.modal {
  position: fixed;
  width: 375px;
  min-height: 200px;
  max-height: 250px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 5px;
  background: #fff;
  overflow: hidden;
  z-index: 9999;
  box-shadow: inset 0 0 1px 0 #000;
  padding: 16px;
}

.modal-title {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  font-size: 20px;
  font-weight: bold;
}

.modal-content {
  width: 100%;
  height: 50px;
  padding: 0 10px;
}

.modal-operator {
  width: 100%;
  padding: 0 20px 20px 0;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
}

.modal-operator-close, .modal-operator-confirm {
  margin-right: 10px;
  width: 122px;
  opacity: 1;
  cursor: pointer;
}

.modal-operator-close:active, .modal-operator-confirm:active {
  opacity: .6;
  transition: opacity .3s;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: .6;
  z-index: 9998;
}


