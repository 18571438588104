.selector-container {
  min-width: 800px;
}
.selector-wrapper {
  display: flex;
}
.server-order-container {
  margin-top: -16px;
  color: #707070;
  font-size: 16px;
  font-weight: bold;
}
.select-group-container {
  padding-left: 100px;
}