.job-detail-container {
  max-width: 1500px;
  position: relative;
  .back-btn {
    position: absolute;
    top: -48px;
    left: -32px;
    width: 108px;
    height: 32px;
    background-color: #ffffff;
    box-shadow: 0px 0px 8px 0px
    rgba(0, 0, 0, 0.16);
    border-radius: 5px;
  }
  .title {
    width: 253px;
    height: 80px;
    max-height: 100px;
    font-size: 48px;
    line-height: 36px;
    color: #062A4E;
    font-weight: bold;
  }
  .host-title {
    width: 92px;
    height: 28px;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 36px;
    color: #707070;
    white-space: nowrap;
  }
  .host-nodata-prompt {
    margin: 20px;
    font-size: 16px;
  }
  .func-wrapper {
    margin-top: 40px;
  }
  .func-zone {
    :last-child {
      margin-right: 0;
    }
  }

  .subtitle {
    margin-top: 0px;
  }



  .btn {
    width: 122px;
    box-shadow: 0px 0px 8px 0px rgba(0, 120, 212, 0.16);
    border-radius: 5px;
    margin-right: 20px;
    :last-child {
      margin-right: 0;
    }
    font-weight: bold;
  }

  .download-btn {
    width: 192px;
  }

  .detail-list {
    width: 40%;
  }

  .prop {
    font-weight: bold;
    color: black;
  }
}

