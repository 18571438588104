.submit-challenge-container {
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .model-name {
    margin-top: 50px;
  }
  .model {
    margin-top: 50px;
  }
  .upload {
    margin: 17px;
    display: flex;
  }
  .button-inline {
    position: absolute;
    margin-left: 90px;
    background-color: #fca311;
    border: none;
  }
  .button-browse {
    background-color: #266593;
  }
  .title {
    margin: 0;
    font-size: 38px;
    margin-left: 16px;
  }
  .title-info {
    margin: 0;
    margin-left: 16px;
  }
  .next-btn {
    margin-top: 100px;
    width: 160px;
    height: 48px;
    font-size: 20px;
    background-color: #266593;
  }
  .title-container {
    margin-left: 130px;
    margin-top: 64px;
    border-left: 20px solid #266593;
  }
  .ant-btn[disabled] {
    background-color: #E8E8E8;
  }
}