.challenge-content {
  background-color: white;

  .self-wrapper {
    margin-top: 78px;
  }

  .description {
    font-size: 16px;
    margin: 32px 0;

    a {
      color: #02A5F4;
      font-weight: bold;
    }
  }

  .bold {
    font-weight: bold;
  }

  th {
    font-weight: bold;
  }

  .challenge-title {
    font-size: 48px;
    color: #062A4E;
    font-weight: bold;
  }

  .line {
    margin-top: 38px;
    border-bottom: 3px solid black;
  }

  .challenge-wrapper {
    padding: 42px;
    background-color: #F9FBFF;
  }

  .content-wrapper {
    padding: 42px;
  }

  .md-wrapper {
    background-color: #F2F8FF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    padding: 42px;
  }

  .intro-header {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
    color: #062A4E;
    text-decoration: underline;
  }

  .row-wrapper {
    display: flex;
    justify-content: center;
  }

  .btn {
    background: #266593;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    padding: 10px 0;
    color: white;
    font-size: 24px;
    font-weight: bold;
    margin: 50px 20px 50px 0;
    width: 300px;
    text-align: center;
    border-radius: 2px;
  }

  .btn:hover {
    background: linear-gradient(315.63deg, #02A5F4 3.96%, #04B2EE 42.99%, #06C1E6 71.92%, #08CBE1 90.32%);
    color: #faedcd;
  }

  .btn-website {
    background: white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    color: #1D1D1D;
    font-size: 24px;
    font-weight: bold;
    margin: 50px 20px 50px 100px;
    width: 320px;
    text-align: center;
    border-radius: 2px;
  }

  .btn-website:hover {
    background: linear-gradient(315.63deg, #02A5F4 3.96%, #04B2EE 42.99%, #06C1E6 71.92%, #08CBE1 90.32%);
    color: #faedcd;
  }

  .info {
    // text-align: center;
    margin-bottom: 34px;
    font-size: 16px;
    color: black;
    margin-top: -58px;
    a {
      font-weight: bold;
      color: #02A5F4;
    }
  }

  @-webkit-keyframes bounce-up {
    25% {
      -webkit-transform: translateY(10px);
    }
    50%,
    100% {
      -webkit-transform: translateY(0);
    }
    75% {
      -webkit-transform: translateY(-10px);
    }
  }

  @keyframes bounce-up {
    25% {
      transform: translateY(10px);
    }
    50%,
    100% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(-10px);
    }
  }

  .animate-bounce-up {
    position: absolute;
    margin-left: 48px;
    margin-top: -24px;
    display: inline-block;
    color: #08CBE1;
    -webkit-animation: bounce-up 1.4s linear infinite;
    animation: bounce-up 1.4s linear infinite;
  }
}