.jobList-container{
  height: 800px;
  max-width: 1500px;
  .title {
    width: 253px;
    height: 80px;
    max-height: 100px;
    font-size: 48px;
    line-height: 36px;
    color: #062A4E;
    font-weight: bold;
  }
  .func-wrapper {
    margin-top: 40px;
  }
  .func-zone {
    :last-child {
      margin-right: 0;
    }
  }
  .filter-text {
    font-size: 14px;
    margin-left: 20px;
  }

  .stop-btn {
    width: 122px;
  }

  .restart-btn {
    width: 122px;
  }

  .delete-btn {
    width: 122px;
  }

  .btn {
    box-shadow: 0px 0px 8px 0px rgba(0, 120, 212, 0.16);
    border-radius: 5px;
    margin-right: 20px;
    font-weight: bold;
  }

  .table-wrapper {
    max-width: 1500px;
  }

}
