body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  background: url("../assets/logo2.png");
  background-size: 180px, 40px;
  width: 180px;
  height: 38px;
  /*background: rgba(0, 0, 0, 0.2);*/
  margin: 10px 10px 16px 0px;
  float: left;
}

.bingLogoLight {
  display: none !important;
}

.CopyrightContainer {
  display: none !important;
}

.ant-menu-horizontal {
  background: linear-gradient(90deg, #245182 16.94%, #253959 79.95%) !important;
}

.ant-menu {
  background: transparent;
}

.menu-about {
  position: absolute;
  font-size: 12px;
  color: white;
  font-weight: bold;
  right: 460px;
}

.ant-layout-content {
  margin-bottom: 64px;
}

.menu-join {
  position: absolute;
  font-size: 12px;
  color: white;
  font-weight: bold;
  right: 360px;
}

.logo-new {
  position: absolute;
  height: 42px;
  margin: 10px 0 0 38px;
}

.logo-new-title {
  position: absolute;
  color: white;
  font-size: 20px;
  font-weight: bold;
  left: 88px;
}

.home-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 64px;
}

.page {
  min-height: 100%;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 64px;
}

@media screen and (max-width: 750px) {
  .menu-about {
    display: none;
  }

  .menu-join {
    display: none;
  }

  .logo {
    display: none;
  }
}
