.info-card-container {
  .wrapper {
    min-height: 200px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 16px 32px 32px 32px;
  }
  .title {
    font-size: 32px;
    color: #062A4E;
  }
  .row {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }
  .left {
    text-align: left;
    margin-left: 10px;
  }
  .row-container {
    margin-top: 40px;
  }
  .key {
    margin-left: 8px;
    font-weight: bold;
  }
  .value {
    margin-right: 20px;
    font-weight: bold;
  }
  .line {
    margin-top: 20px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }
  .button {
    padding: 12px 24px;
    margin-top: 36px;
    color: white;
    font-size: 24px;
    font-weight: bold;
    border-radius: 4px;
    background: #266593;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  }
  .button:hover {
    background: linear-gradient(315.63deg, #02A5F4 3.96%, #04B2EE 42.99%, #06C1E6 71.92%, #08CBE1 90.32%);
    color: #faedcd;
  }
  .link {
    font-size: 16px;
    background-color: white;
    color: black;
    font-weight: bold;
    border-radius: 5px;
  }
}
