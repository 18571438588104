.signup-container{
  .supplementary-information {
    width: 669px;
    height: 80px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 48px;
    font-weight: 700;
    font-stretch: normal;
    line-height: 36px;
    letter-spacing: 0.5px;
    color: #0078d4;
  }

  .signup-panel-container {
    height: 600px;
    border-color: black;
    border-width: thin;
  }
  .contact {
    padding: 20px;
    text-aligh: center;
    a {
      color: #6a6a6a;
    }

  }

  .row {
    height: 80px;
  }

  .signup-button {
    height: 40px;
    border-radius: 5px;
  }

}