.landing-container {
  background: linear-gradient(180deg, #1F293C 0%, #1D2A3F 14.9%, #161B25 100%);
  z-index: -100;

  .landing-footer {
    background-color: black;
    left: 0px;
    top: 0px;
    width: 100%;
  }

  .landing-header-container {
    display: flex;
    height: 64px;
    justify-content: space-between;
  }

  .bg-bottom {
    z-index: -1;
    height: 700px;
    //position: absolute;
    margin-top: -600px;
  }

  .landing-footer-container {
    z-index: 100;
    width: 92%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .landing-header-container ul {
    display: flex;
    margin-top: 15px;
  }

  .landing-nav li {
    color: white;
    list-style: none;
    margin-right: 20px;
  }

  .landing-nav-item {
    position: relative;
    height: 24px;
    top: 10px;
  }

  .landing-nav-item a{
    font-weight: bolder;
    font-size: 12px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .hero-row {
    display: flex;
    max-width: 1160px;
    margin: auto;
  }

  .left-info {
    margin-left: 10px;
  }

  .img-right {
    height: 400px;
    // margin-right: 60px;
  }

  .bg-title-info {
    margin-top: 100px;
    margin-right: 10px;
    font-size: 27px;
    font-weight: 400;
    color: white;
  }

  .bg-title {
    font-size: 38px;
    font-weight: 700;
    color: #ffffff;
  }

  .bg-description {
    font-weight: 350;
    margin-right: 10px;
    font-size: 14px;
    color: #ffffff;
  }

  .login {
    margin-top: 50px;
    //float: right;
    // margin-left: -15px;
    width: 200px;
    margin-bottom: 200px;
    //height: 103px;
    z-index: 100;
    cursor: pointer;
  }

  .challenge-container {
    display: flex;
    max-width: 1160px;
  }

  .challenge-text {
    margin-left: 10px;
  }

  // .challenge-pics {
  //   margin-right: 100px;
  // }

  .introduction-container {
    display: flex;
    max-width: 1160px;
  }

  .news-container {
    display: flex;
    max-width: 1160px;
  }

  .news-pic {
    margin-top: 30px;
  }

  .news-text {
    margin-left: 60px;
    margin-top: 40px;
  }

  .contact {
    padding: 20px;

    a {
      color: #6a6a6a;
    }
  }

  .title {
    font-size: 20px;
    font-weight: 500;
    color: white;
  }

  .right {
    text-align: right;
  }

  .left {
    text-align: left;
  }

  .description {
    color: white;
    font-weight: 350;
  }

  .photo {
    width: 600px;
    border-radius: 5px;
  }

  .photo:hover {
    box-shadow: 0px 0px 80px rgba(255, 255, 255, 0.2);
  }

  .margin-right {
    margin-right: 132px;
  }

  .margin-top {
    margin-top: 30px;
  }

  .margin-lift {
    margin-top: -230px;
  }

  .padding-top {
    padding-top: 28px;
  }

  .padding-left {
    padding-left: 140px;
  }

  .padding-right {
    padding-right: 30px;
  }

  .landing-profile {
    margin-top: 3px;
  }

  .footer-landing {
    height: 64px;
    background: black;
    left: 0px;
    top: 0px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .line {
    display: flex;
    margin: 200px auto 80px auto;
    justify-content: space-between;
    max-width: 1160px;

    p {
      color: #02A5F4;
      font-size: 30px;
      font-weight: 600;
    }

    .border {
      width: 100%;
      margin-left: 30px;
      margin-top: 28px;
      border-top: 3px solid rgba(2, 165, 244, 0.2);
    }
  }

  .linksInRect {
    //position: absolute;
    margin-left: 110px;
    padding: 8px 15px;
    color: #02A5F4;
    font-size: 20px;
    font-weight: 630;border: 2px solid #02A5F4;
    box-sizing: border-box;
    border-radius: 10px;
  }

  .links {
    color: #02A5F4;
    font-size: 20px;
    font-weight: 630;
    margin-left: 10px;
    display: inline-block;
  }

  .rectangle {
    width: 188px;
    margin-left: 110px;
    margin-top: -60px;
    margin-bottom: 40px;
  }

  .rectangle-container {
    width: 520px;
  }

  .background:hover {
    background: linear-gradient(180deg, #47ADD6 0%, #377FB7 26.87%, #264F97 100%);
    box-shadow: 0px 0px 80px rgba(255, 255, 255, 0.2);
    border-radius: 8px;
  }

  .background {
    background: linear-gradient(180deg, #26364A 0%, #1C273C 100%);
    border-radius: 8px;
    height: 160px;
    width: 480px;
    margin-left: 110px;
    border-radius: 5px;
    margin-bottom: 38px;
    margin-top: 54px;

    .description {
      margin-top: -20px;
      font-style: normal;
      font-weight: normal;
      font-weight: 350;
    }

    .vector {
      position: absolute;
      height: 90px;
      margin-top: 34px;
      margin-left: 20px;
    }
  }

  .introduction-video {
    margin-top: 30px;
  }

  .introduction-square-container {
    display: flex;
    margin-left: 110px;
    padding-top: 30%;
    justify-content: space-between;
  }

  .square-container {
    display: flex;
    margin-left: 110px;
    justify-content: space-between;
  }

  .square {
    background: linear-gradient(180deg, #26364A 0%, #1C273C 100%);
    border-radius: 8px;
    height: 170px;
    width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .square:hover {
    background: linear-gradient(180deg, #47ADD6 0%, #377FB7 26.87%, #264F97 100%);
    box-shadow: 0px 0px 80px rgba(255, 255, 255, 0.2);
    border-radius: 8px;
  }

  .vector-git {
    margin-top: 20px;
    margin-bottom: 10px;
    height: 60px;
  }

  .features-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .background-features:hover {
    background: linear-gradient(180deg, #47ADD6 0%, #377FB7 26.87%, #264F97 100%);
    box-shadow: 0px 0px 80px rgba(255, 255, 255, 0.2);
    border-radius: 8px;
  }

  .background-features {
    width: 270px;
    height: 420px;
    background: linear-gradient(180deg, #26364A 0%, #1C273C 100%);
    border-radius: 8px;
    border-radius: 8px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 100px;

    .title {
      margin: 20px;
      text-align: center;
    }

    .description {
      position: absolute;
      margin: 0px 40px;
      text-align: center;
      width: 190px;
    }
  }

  .arrow {
    display: inline-block;
    height: 15px;
    margin-left: 8px;
    margin-top: -7px;
  }

  .ellipse {
    display: inline-block;
    height: 10px;
    margin-left: 12px;
    margin-top: 30px;
  }

  .icon {
    height: 120px;
    margin-left: 76px;
    margin-top: 30px;
  }

  .background-join {
    position: relative;
    background: linear-gradient(265.65deg, #47ADD6 13.4%, #254E97 86.23%);
    box-shadow: 0px 0px 40px rgba(55, 131, 174, 0.1);
    border-radius: 8px;
    border-radius: 10px;
    height: 260px;
    margin: 180px auto 280px auto;
    display: flex;
    justify-content: center;
    z-index: 100;
    max-width: 1160px;

    .bg-title-bottom {
      font-size: 45px;
      font-weight: 700;
      color: #ffffff;
      z-index: 100;
      padding-top: 86px;
      // padding-left: 30px;
      padding-right: 380px;
    }

    .description {
      padding-left: 100px;
      margin-top: -50px;
    }

    .join {
      height: 60px;
      margin-top: 100px;
    }
  }

  .footer {
    // position: fixed;
    height: 64px;
    background: linear-gradient(184.01deg, #245182 15.97%, #253959 109.62%);
    width: 100%;
    display: flex;
    align-items: center;
  }

  .footer-logo {
    display: flex;
    align-items: center;

    .logo-title {
      margin-left: 6px;
      color: white;
      display: flex;
      align-items: center;
    }

    .logo-title-big {
      margin: 0px;
      padding: 0px;
      font-size: 20px;
      font-weight: bold;
    }

    .logo-title-small {
      margin: 0px;
      padding: 4px 0 0 0;
      font-weight: 200;
      font-size: 12px;
      margin-left: 8px;
    }
  }

  .logo-solo {
    height: 42px;
    margin-left: 38px;
  }

  .nav {
    display: flex;
    margin: auto 0;
  }

  .nav li {
    margin: auto;
    font-style: normal;
    font-weight: bold;
    list-style: none;
    color: white;
    a {
      color: white;
      font-size: 12px;
      padding-right: 58px;
    }
  }

  @media screen and (max-width: 750px) {
    .bg-title-info {
      display: none;
    }

    .left-info {
      margin: 100px 20px 100px 40px;
    }

    .landing-footer-logo {
      display: none;
    }

    .landing-logo {
      display: none;
    }

    .footer-logo {
      display: none;
    }

    .profile {
      display: none;
    }
  }

  @media screen and (max-width: 1200px) {
    .bg-bottom {
      display: none;
    }

    .left-info {
      margin-left: 40px;
    }

    .footer-contact {
      display: none;
    }

    .bg-title-bottom {
      display: none;
    }

    .description-bottom {
      display: none;
    }

    .landing-profile {
      display: none;
    }

    .img-right {
      display: none;
    }

    .challenge-pics {
      display: none;
    }

    .challenge-text {
      margin-right: 100px;
    }

    .news-pic {
      display: none;
    }

    .background-join {
      display: none;
    }

    .challenge-text {
      margin: 40px;
    }

    .bg {
      margin: 30px;
    }

    .news-text {
      margin: 40px;
    }

    .line {
      margin: 40px;
    }
  }
}

