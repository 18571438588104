.customInfobox {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  max-width: 200px;
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;
  pointer-events: auto !important;
}

.customInfobox .title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}
