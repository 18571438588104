.first-tab-container {
  padding-bottom: 150px;
  height: 750px;
  .type {
    margin-top: 50px;
  }
  .description {
    margin-top: 50px;
  }
}
