.second-tab-container {
  padding-bottom: 150px;
  height: 750px;
  .config-row {
    margin-top: 30px;
  }
  .probing {
    .row-container {
      margin-top: 50px;
    }
  }
}
