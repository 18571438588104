.about-join-container {
  background: #F9FAFF;
  font-style: normal;

  .header-container {
    font-style: normal;
    font-weight: bold;
    height: 200px;
    background: linear-gradient(90deg, #245182 16.94%, #253959 79.95%);
  }

  .header-content {
    display: flex;
    height: 64px;
    justify-content: space-between;
  }

  .logo {
    margin-left: 38px;
  }

  .nav {
    display: flex;
    margin: auto 0;
  }

  .nav li {
    margin: auto;
    font-style: normal;
    font-weight: bold;
    list-style: none;
    color: white;
    a {
      color: white;
      font-size: 12px;
      padding-right: 58px;
    }
  }

  .profile {
    font-size: 14px;
  }

  .body-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .title {
    margin-top: 10px;
    padding: 15px 36px;
    margin-bottom: 30px;
    color: #062A4E;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
  }

  .back {
    background: #DBFBFE;
    border-radius: 10px;
    width: 30px;
    white-space:nowrap;
  }

  .content {
    width: 90%;
    max-width: 1200px;
    margin-top: -100px;
    background-color: white;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .margin-top {
    padding-top: 100px;
  }

  .big-title {
    position: absolute;
    left: 18%;
  }

  .margin-left {
    margin-left: 10px;
  }

  .content-next {
    width: 90%;
    background: #F9FBFF;
    max-width: 1200px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
  }

  .row {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-around;
  }

  .text {
    width: 46%;
    font-style: normal;
    font-weight: normal;
    color: #062A4E;
  }

  .pic {
    width: 500px;
  }

  .line {
    height: 0px;
    width: 90%;
    margin-bottom: 40px;
    border-bottom: 1px solid #C4C4C4;
  }

  p {
    margin-right: 30px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
  }

  .underline {
    text-decoration: underline #062A4E;
  }

  .info {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 30px 30px 0px;
  }

  .role {
    margin-left: 30px;
    background: #1D5E9E;
    border-radius: 10px;
    padding: 10px 0px;
    height: 52px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: white;
    width: 150px;
    text-align: center;
  }

  .name {
    // margin-left: 100px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    padding: 10px 15px;
    color: #062A4E;
  }

  .list-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .footer {
    // position: fixed;
    height: 64px;
    background: linear-gradient(184.01deg, #245182 15.97%, #253959 109.62%);
    width: 100%;
    display: flex;
    align-items: center;
  }

  .footer-logo {
    display: flex;
    align-items: center;

    .logo-title {
      margin-left: 6px;
      color: white;
      display: flex;
      align-items: center;
    }

    .logo-title-big {
      margin: 0px;
      padding: 0px;
      font-size: 20px;
      font-weight: bold;
    }

    .logo-title-small {
      margin: 0px;
      padding: 4px 0 0 0;
      font-weight: 200;
      font-size: 12px;
      margin-left: 8px;
    }
  }

  .logo-solo {
    height: 42px;
    margin-left: 38px;
  }

  .footer-container {
    width: 100%;
    margin: auto;
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .footer-contact {
    display: flex;
  }

  .contactUs {
    color: white;
    font-size: 14px;
    font-weight: 500;
    margin: auto;
    padding-right: 8px;
  }

  .email {
    color: white;
    font-size: 10px;
    font-weight: 100;
    margin: auto;
    padding-top: 2px;
  }

  .ant-tabs-tab-btn {
    color: rgba(0, 0, 0, 0.25);
    font-size: 26px;
    margin-bottom: 100px;
    margin-top: 100px;
  }

  .ant-tabs-tab-active {
    color: black !important;
  }

  .ant-tabs-ink-bar {
    background-color: transparent !important;
  }

  .bg {
    position: absolute;
    width: 60%;
    right: 19%;
    top: 1600px;
  }

  .pic-small {
    position: absolute;
    height: 80px;
    right: 29%;
  }

  .pic-box {
    position: absolute;
    height: 160px;
    right: 20%;
    margin-top: -70px;
  }

  .earth {
    position: absolute;
    height: 140px;
    left: 43%;
    margin-top: -54px;
  }

  .bold {
    font-weight: bold;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .img-container {
    display: flex;
    margin-top: 100px;
    flex-wrap: wrap;
    justify-content: center;

    .pic-gallery {
      height: 200px;
      margin: 10px;
      border-radius: 19px;
      filter: drop-shadow(0px 4px 40px rgba(29, 94, 158, 0.2));
    }
  }

  .center {
    text-align: center;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
  }

  .pic-info {
    height: 80px;
  }

  .names {
    text-align: center;
    white-space: nowrap;
    font-style: normal;
    font-weight: bold;
    color: #062A4E;
    margin-top: 10px;
    margin-bottom: 80px;
    font-size: 14px;
  }

  .padding-top {
    padding-top: 100px;
  }

  .join-content-next {
    width: 90%;
    max-width: 1200px;
    background: #F9FBFF;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
    margin-bottom: 100px;
    padding-bottom: 80px;
  }

  .join-ul {
    background: #F2F8FF;
    border-radius: 0px;
    padding: 46px;
    margin-bottom: 0px;

    li {
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 48px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      color: #062A4E;
    }
  }

  .tab-container {
    margin-left: 10%;
    width: 80%;
  }

  .info-bg{
    padding: 30px;
    margin: 0px;
    background: linear-gradient(265.65deg, #47ADD6 13.4%, #254E97 86.23%);
    box-shadow: 0px 0px 40px rgba(55, 131, 174, 0.1);
  }

  .white-title {
    font-size: 28px;
    color: white;
    font-style: normal;
    font-weight: bold;

    a {
      color: white;
      text-decoration: underline white;
    }
  }

  .white-info {
    font-size: 18px;
    color: white;
    width: 70%;

    a {
      color: #00FFC6;
      text-decoration: underline #00FFC6;;
    }
  }

  .flex-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .flex-box-bottom {
    margin-top: 40px;
    margin-bottom: 30px;
    margin-left: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    img {
      margin-left: 10px;
      margin-right: 40px;
    }
  }

  .title-container {
    margin-left: 30px;
  }

  @media screen and (max-width: 750px) {
    .logo {
      display: none;
    }

    .footer-logo {
      display: none;
    }

    .info-bg {
      display: none;
    }

    .join-content-next {
      display: none;
    }

    .body-container {
      margin-bottom: 100px;
    }

    .content-next {
      display: none;
    }

    .text {
      width: 84%;
    }
  }

  @media screen and (max-width: 1200px) {
    .profile {
      display: none;
    }

    .footer-contact {
      display: none;
    }

    .pic {
      display: none;
    }

    .bg {
      display: none;
    }

    .pic-small {
      display: none;
    }
  }
}